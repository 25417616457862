<template>
	<div class="dashboard-content" data-app>
		<!-- Content -->
		<simple-list
			:title="title"
			:dense="true"
			:headers="headers"
			:items="items"
			:totalItems="totalItems"
			:itemsPerPage="itemsPerPage"
			:loadingText="loadingText"
			:hideFooter="true"
			:multiSort="false"
			:loading="loading"
			:class="'card mb-4'"
			:sortable="true"
			:page="page"
			:pages="pages"
			:extraButtoms="extraButtoms"
			:showCreate="false"
			:showDelete="false"
			:buttonLink="'empresas/ficha'"
			@create:item="handleCreate()"
			@edit:item="handleEdit($event)"
			@delete:item="deleteItem($event)"
			@update:page="pagination($event)"
		/>
		<modal name="crudModal" :height="'70%'" :width="'80%'" :adaptive="true">
			<h3 class="text-center" v-if="flow == 'edit'">Editar {{ editText }}</h3>
			<div class="card" style="padding: 2rem; margin: 20px">
				<h5 style="margin-bottom: 20px">Datos generales</h5>
				<div class="row">
					<v-form
						ref="formCompany"
						v-model="valid"

						style="width: 100%"
						class="row"
					>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field
								required
								:rules="descriptionRules"
								label="Nombre"
								v-model="name"
							></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field
								required
								:rules="descriptionRules"
								label="Descripción"
								v-model="description"
							></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field
								required
								:rules="emailRules"
								label="Email"
								v-model="email"
								type="email"
							></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field
								required
								:rules="descriptionRules"
								label="Teléfono"
								v-model="phone"
							></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field
								required
								:rules="descriptionRules"
								label="Palabra clave"
								v-model="keyword"
							></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field
								required
								:rules="descriptionRules"
								label="Enlace"
								v-model="link"
							></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-select
								:rules="[(v) => !!v || 'El país es requerido']"
								:items="countries"
								:item-text="'name'"
								:item-value="'code'"
								@change="getCities()"
								v-model="countryId"
								label="País"
								type="text"
							></v-select
						></v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-select
								:rules="[(v) => !!v || 'La ciudad es requerida']"
								:items="cities"
								:item-text="'name'"
								:item-value="'code'"
								v-model="cityId"
								label="Ciudad"
								type="text"
							></v-select
						></v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field
								required
								:rules="descriptionRules"
								label="Latitude"
								v-model="latitude"
							></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field
								required
								:rules="descriptionRules"
								label="Longitude"
								v-model="longitude"
							></v-text-field>
						</v-col>
						<v-col cols="12" lg="4" md="6" sm="12">
							<v-text-field
								required
								:rules="descriptionRules"
								label="Dirección"
								v-model="address"
							></v-text-field>
						</v-col>
						<v-col cols="12" lg="6" md="6" sm="12">
							<div
								class="row"
								style="display: flex; justify-content: center; align-items: center"
							>
								<img
									v-if="flow == 'edit' && item.logo"
									:src="item.logo.route"
									:alt="item.name"
									style="width: 40%"
								/>
								<v-file-input
									v-model="image"
									chips
									ref="file"
									@change="handleFileUpload()"
									show-size
									accept="image/png, image/jpeg, image/jpg, image/gif"
									:label="'Seleccione nuevo logo'"
									style="width: 60%"
								></v-file-input>
							</div>
						</v-col>
						<v-col cols="12" lg="3" md="6" sm="12">
							<v-checkbox label=" Destacada" v-model="outstanding"></v-checkbox>
						</v-col>
						<v-col cols="12" lg="3" md="6" sm="12">
							<v-checkbox label=" Activa" v-model="active"></v-checkbox>
						</v-col>
					</v-form>
				</div>
			</div>
			<v-col cols="12" style="margin-bottom: 20px; height: 10%">
				<div class="row">
					<v-btn
						style="margin: auto; margin-top: 10px; color: #fff"
						v-if="flow == 'create'"
						:disabled="!valid"
						dark
						color="#003a56"
						@click="createItem()"
					>
						Crear
					</v-btn>
					<v-btn
						style="margin: auto; margin-top: 10px; color: #fff"
						v-if="flow == 'edit'"
						:disabled="!valid"
						dark
						color="#003a56"
						@click="saveItem()"
					>
						Guardar
					</v-btn>

					<v-btn
						style="margin: auto; margin-top: 10px; color: #fff"
						color="#003a56"
						dark
						@click="reset"
					>
						Limpiar
					</v-btn>
					<v-btn
						style="margin: auto; margin-top: 10px; color: #fff"
						color="#003a56"
						dark
						@click="$modal.hide('crudModal')"
					>
						Cancelar
					</v-btn>
				</div>
			</v-col>
		</modal>
	</div>
</template>

<script>
	import SimpleList from 'Components/Listing/SimpleList.vue'
	export default {
		name: 'Companies',
		props: ['companyType'],
		components: {
			SimpleList,
		},
		data() {
			return {
				config: {},
				companies: [],
				loading: false,
				headers: [
					{ text: 'Nombre', value: 'name' },
					{ text: 'Email', value: 'email' },
					{ text: 'Teléfono', value: 'phone' },
					{ text: 'Destacado', value: 'outstandingText' },
					{ text: 'Acciones', value: 'actions', align: 'center', width: '120px' },
				],
				typeDatas: [
					{ id: '2', name: 'DATOS DE LA EMPRESA FRANQUICIANTE' },
					{ id: '3', name: 'DATOS OPERATIVOS' },
					{ id: '4', name: 'INFORMACIÓN ECONÓMICA' },
					{ id: '5', name: 'OTROS DATOS IMPORTANTES' },
					{ id: '6', name: 'RESEÑA HISTÓRICA' },
					{ id: '7', name: 'DATOS DE CONTACTO' },
					{ id: '8', name: 'TIPO DE FRANQUICIAS QUE OFRECEN' },
					{ id: '9', name: 'INFORMACIÓN SOBRE UBICACIÓN Y LOCAL REQUERIDO' },
				],
				selectCompanyType: [
					{ id: 0, name: 'Franquicias' },
					{ id: 1, name: 'Asociaciones de Franquicias' },
					{ id: 2, name: 'Consultores' },
					{ id: 3, name: 'Proveedores' },
					{ id: 4, name: 'Oferta Inmobiliaria' },
				],
				items: [],
				page: 1,
				pages: 1,
				item: {},
				totalItems: 0,
				itemsPerPage: 10,
				editText: '',
				flow: '',
				active: false,
				type: 0,
				address: '',
				cityId: '',
				email: '',
				keyword: '',
				latitude: '',
				longitude: '',
				link: '',
				phone: '',
				name: '',
				userId: '',
				description: '',
				outstanding: false,
				cities: [],
				countries: [],
				users: [],
				countryId: '',
				selectCountryData: [],
				selectCityData: [],
				loadingText: 'Cargando registros',
				datas: [],
				image: null,
				arrayOfData: [],
				valid: true,
				descriptionRules: [
					(v) => !!v || 'La descripción es requerida',
					(v) =>
						(v.length <= 100) ||
						'La descripción debe poseer menos de 100 caracteres',
				],
				emailRules: [
					(v) => !!v || 'E-mail es requerido',
					(v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
				],
				extraButtoms: [
					{
						text: 'Datos publicados',
						link: 'empresas/ficha',
						icon: 'mdi-file-document-edit',
					},
					{
						text: 'Galería de imágenes',
						link: 'empresas/galeria',
						icon: 'mdi-image-multiple',
					},
				],
			}
		},
		watch: {
			companyType(newValue, oldValue) {
				this.getCompanies()
			},
		},
		computed: {
			title() {
				switch (this.companyType) {
					case 0:
						this.editText = 'Franquicia'
						return 'Mis Franquicias'
					case 1:
						this.editText = 'Asociación de Franquicia'
						return 'Mis Asociaciones de Franquicias'
					case 2:
						this.editText = 'Consultor'
						return 'Mis Consultores'
					case 3:
						this.editText = 'Proveedor'
						return 'Mis Proveedores'
					case 4:
						this.editText = 'Oferta Inmobiliaria'
						return 'Mi Oferta Inmobiliaria'
					default:
						return ''
				}
			},
		},
		created() {
			this.getCompanies()
			this.getCountries()
		},
		methods: {
			handleFileUpload() {
				if (this.$refs.file.files) {
					this.image = this.$refs.file.files[0]
				}
			},
			async getCompanies() {
				this.loading = true
				this.items = []
				this.loadingText = 'Cargando registros'
				axios
					.get(
						process.env.VUE_APP_API_DIRECTORY +
							'companies?page=' +
							this.page +
							'&type=' +
							this.companyType
					)
					.then((response) => {
						this.items = response.data.data
						if (this.items.length < 1) {
							this.loadingText = 'No hay registros'
						}
						this.totalItems = response.data.total
						this.itemsPerPage = response.data.per_page
						this.page = response.data.from
						this.pages = response.data.last_page
						this.items.map((x) => {
							let outstandingText
							switch (x.outstanding) {
								case 1:
									outstandingText = 'Si'
									break
								default:
									outstandingText = 'No'
									break
							}
							x.outstandingText = outstandingText
							x.userFullName = x.user.name + ' ' + x.user.lastname + ' | ' + x.user.email
						})
					this.loading = false})

			},

			async getCities() {
				this.loading = true
				axios
					.get(
						process.env.VUE_APP_API_DIRECTORY + 'cities?limit=500&country=' + this.countryId,
						this.config
					)
					.then((response) => {
						this.cities = response.data
						this.cities.map((city) => {
							city.code = city.id
						})
					this.loading = false})

			},

			async getCountries() {
				this.loading = true
				axios
					.get(
						process.env.VUE_APP_API_DIRECTORY + 'countries?limit=300' + this.countryId,
						this.config
					)
					.then((response) => {
						this.countries = response.data
						this.countries.map((country) => {
							country.code = country.id
						})
					this.loading = false})

			},

			async handleEdit(item) {
				this.flow = 'edit'
				this.item = item
				this.image = null
				this.userId = this.item.user_id
				this.name = this.item.name
				this.description = this.item.description
				this.email = this.item.email
				this.phone = this.item.phone
				this.latitude = this.item.latitude
				this.longitude = this.item.longitude
				this.keyword = this.item.keyword
				this.link = this.item.link
				this.cityId = this.item.city_id
				this.address = this.item.address
				this.countryId = this.item.city.country_id
				this.active = this.item.active == 0 ? false : true
				this.outstanding = this.item.outstanding == 0 ? false : true

				this.getCities()
				this.$modal.show('crudModal')
			},

			validate() {
				this.$refs.formCompany.validate()

			},

			reset() {
				this.$refs.formCompany.reset()

			},

			async saveItem() {
				this.loading = true
				let data = []
				let formData = new FormData()
				this.active = this.active ? 1 : 0
				this.outstanding = this.outstanding ? 1 : 0

				this.config.headers = {
					'Content-Type': 'multipart/form-data',
				}
				if (this.image) {
					formData.append('file', this.image)
				}
				if (this.item.logo) {
					formData.append('fileId', this.item.logo.id)
				}
				formData.append('fileType', 'logo')
				formData.append('_method', 'patch')
				formData.append('name', this.name)
				formData.append('user_id', this.userId)
				formData.append('type', this.type)
				formData.append('city_id', this.cityId)
				formData.append('description', this.description)
				formData.append('link', this.link.toLowerCase())
				formData.append('latitude', this.latitude)
				formData.append('longitude', this.longitude)
				formData.append('address', this.address)
				formData.append('keyword', this.keyword.toLowerCase())
				formData.append('phone', this.phone)
				formData.append('email', this.email)
				formData.append('active', this.active)
				formData.append('outstanding', this.outstanding)
				data.forEach((singleData) => {
					formData.append('data[]', singleData)
				})

				axios
					.post(
						process.env.VUE_APP_API_DIRECTORY + 'companies/' + this.item.id,
						formData,
						this.config
					)
					.then((response) => {
						this.page = 1
						this.getCompanies()
						this.$modal.hide('crudModal')
					this.loading = false})

			},

			async pagination(page) {
				this.page = page
				this.items = []
				await this.getCompanies()
			},
		},
	}
</script>
<style lang="scss">
	.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
	.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
	.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
	.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
	.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
	.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
		padding: 0 10px;
		transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
	}
	.col-lg-4,
	.col-md-6,
	.col-sm-12 {
		padding: 0px auto !important;
	}
	.theme--light.v-input {
		color: rgba(0, 0, 0, 0.87);
		padding: 0px;
		margin: 0px;
	}
	.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
		display: flex;
		flex: 1 1 auto;
		position: relative;
		width: 100%;
	}
	.vm--modal {
		height: auto !important;
		max-height: 80% !important;
		overflow-x: hidden;
		overflow-y: scroll;
	}
</style>
